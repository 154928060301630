<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <div v-if="!loading">
      <b-card
        v-if="rows.length === 0"
        :title="$t('promotions.promotionpages')"
      >
        <div
          class="text-center"
        >
          <h5>{{ $t('promotions.nopromotionpages') }}</h5>
          <p>{{ $t('promotions.addpromotionpage') }}:</p>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            :to="{ path: `/users/${$route.params.userId}/sites/${$route.params.siteId}/promotions/${$route.params.promotionId}/promotionpages/create`}"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            {{ $t('promotions.promotionpageadd') }}
          </b-button>
        </div>
      </b-card>
      <promotion-pages-table
        v-else
        :rows="rows"
        @refresh="getPromotionPages()"
      />
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  BButton,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import PromotionPagesTable from './PromotionPagesTable.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    PromotionPagesTable,
    BButton,
    UserToolbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      authUserData: getUserData(),
      account: {},
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      site: [],
      promotion: [],
      rows: [],
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    async getPromotionPages() {
      this.rows = []
      const params = {
        promotion_id: this.$route.params.promotionId,
      }
      const responseData = await useJwt.getPromotionPages(params)
      this.rows = responseData.data.promotionpages || []
      this.loading = false
      this.setBreabcrumbs()
    },
    async getPromotionData() {
      const responseData = await useJwt.getPromotion(this.$route.params.promotionId)
      this.promotion = responseData.data.promotion || []
      this.getPromotionPages()
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.getSiteData()
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || []
      this.getPromotionData()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'Sites',
          to: `/users/${this.$route.params.userId}/sites`,
        },
        {
          text: this.site.title,
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}`,
        },
        {
          text: 'Promotions',
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}/promotions`,
        },
        {
          text: this.promotion.title,
          active: true,
        },
      ]
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
